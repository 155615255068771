import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"
import Typical from 'react-typical'


const query = graphql`
    {
      file(relativePath: {eq: "ibad-img.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
const Hero = () => {
  const {
    file:{
      childImageSharp: {fluid},
    }
  } = useStaticQuery(query)

  return (
  <header className="hero">
    <div className="section-center hero-center">
      <article className="hero-info">
        
        <p class="welcome-message">Hi, my name is Ibad Khan and I am a developer</p>
        <h1 className="heading-text">I build dynamic and scalable web applications facilitating cloud services</h1>
        <h4>
        {/* <Typical
        loop={Infinity}
        wrapper="b"
        steps={[' A Developer', 2000, 'learn continuously', 1000, 'take challenges', 1000]}
          /> */}
        </h4>
        <a href="https://drive.google.com/file/d/1Sx1QdggIiTk-HZPP8P22n0lB91_C45LI/view?usp=sharing" target="_blank" className="btn">
         Resume
        </a>
        <Link to="/contact" className="btn">
          Contact me
        </Link>
        
        <SocialLinks />
      </article>
      {/* <Image fluid={fluid} className="hero-img" /> */}

      {/* <img src="../assets/ibad-photo.jpg" width="200px"/> */}
    </div>
  </header>
  )
}

export default Hero
