import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import Markdown from "react-markdown";
import { FaGithubSquare, FaShareSquare } from "react-icons/fa"
import ReactPlayer from 'react-player'

import Modal from 'react-modal';


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    width:'80%',
    height: "80%" ,
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)

const Project = ({ description, title, github, stack, url, video_url, image, index }) => {
  var subtitle;
  const [modalIsOpen,setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
 
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#111';
  }
 
  function closeModal(){
    setIsOpen(false);
  }
  return (
    <article className="project">
      
      <div className="project-info" >

        <div className="project-image">
          {image && (
            <Image fluid={image.childImageSharp.fluid} className="projects-img"/>
          )}
        </div>

        <div className="project-content">
          <h3>{title || "default title"}</h3>
          <Markdown source={description}/>
          <div className="project-stack">
            {stack.map(item => {
              return <span key={item.id}>{item.title}</span>
            })}
          </div>
          <div className="project-links">
            <a href={github} target="_blank">
              Github
            </a>
            {url && (
              <a href={url} target="_blank">
              Live demo
              </a>
            )}

            {video_url && (
              <a href="#" onClick={openModal}>
              See video
              </a>
            )}
           
            {/* <a href="#" onClick={openModal}>See more</a> */}
          </div>
        </div>     

      </div>
      
     

       <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <h2 ref={_subtitle => (subtitle = _subtitle)}>{title}</h2>

           {video_url && (
          <ReactPlayer
              url={video_url}
              width="100%"
              height="80%"
            />
          )}

          
        </Modal>
    </article>

  )
}

export default Project