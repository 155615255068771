import React from "react"
import Title from "./Title"
// import SkillBar from 'react-skills-bars';
import Technicals from "../constants/technicalSkills"



const OtherSkills = ({heading, text})=>{
  return (
    <div>
      <p>
        <span><strong>{heading}: </strong>
        </span><span>{text}</span>
      </p>
    </div>
  );
}
const TechnicalSkills = () => {
  return (
    <section className="section bg-grey">
        <Title title="Skills & Knowledge"/>
        <div className="section-center services-center">
            <article className="service">
              <h3>Other skills</h3>
              <OtherSkills heading="Teamwork" 
              text="Actively listen to team member's ideas and concerns and Expressing attentiveness by asking questions for clarification. Acknowledging other points of view with encouragement, clearly communicating ideas with a professional and friendly tone. Additional things help me to better work with team members such as decision making, organizing, accountability, positive attitude, expanding ideas
              "
              
              />
              <OtherSkills heading="Patience" 
                          text="
                          Problem-solving most of the time requires complex effort which often includes a long process. In project kick-off, planning, development, testing, deployment, updates... Most of these tasks and activities take a lot of time.  Giving enough time, enough chances to make errors and fix them, so being patient is one of my ally in this mission
                          "/>
              <OtherSkills heading="Open-mindedness and adaptability" 
              text="
              Accept self weaknesses and tackle them by asking questions, open to learning from others, willing to listen and appreciate others. Never afraid to try and experiment, many times I have worked on different ideas which I was not interested in.
              "
              />

            </article>
            <article className="service">
                    <h3>Technical skills</h3>
                     <Technicals></Technicals>
            </article>
            
      </div>
    </section>
  )
}


export default TechnicalSkills