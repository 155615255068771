

import React from "react"
import {
  FaFacebookSquare,
  FaLinkedin,
  FaDribbbleSquare,
  FaBehanceSquare,
  FaTwitterSquare,
  FaGithub,
  FaYoutube
} from "react-icons/fa"

const data = [
  {type: "React", level: 90},
  {type: "NodeJS", level: 80},
  {type: "Bootstrap", level: 85},
  {type: "JavaScript", level: 90},
  {type: "PHP", level: 78},
  {type: "Java", level: 70},
  {type: "ElectronJS", level: 65},
  {type: "MySql", level: 65},
  {type: "HTML", level: 90},
  {type: "CSS", level: 85},
  {type: "RestAPI", level: 85},
]
const links = data.map(link => {
  return (
    <li className="technical-skill-name">
      {link.type}
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul>{links}</ul>
  )
}

 
